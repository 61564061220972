import React from "react";
import "./DescriptionBox.css";

const DescriptionBox = () => {
  return (
    <div className="descriptionbox">
      <div className="descriptionbox-navigator">
        <div className="descriptionbox-nav-box"> Description </div>
        <div className="descriptionbox-nav-box fade">Reviwes (122)</div>
      </div>
      <div className="descriptionbox-description">
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Atque, eos
          architecto. Voluptatibus nam omnis dignissimos id similique ab nihil
          quisquam eos eveniet minus, possimus vitae a sit dolore vero
          perspiciatis. Deserunt, non provident? Numquam mollitia nesciunt optio
          magnam rerum laudantium repellendus officiis, ut tenetur ipsum
          corporis dolores cum iste dolore quia nisi nemo vel reiciendis
          necessitatibus. Debitis quaerat maiores error. Aperiam eveniet modi
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum
          necessitatibus, veniam, excepturi, recusandae reiciendis voluptatibus.
        </p>
      </div>
    </div>
  );
};

export default DescriptionBox;
